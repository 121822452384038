/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
import { useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { addNewLines } from 'components/sections/articles/richTextOptions';
import { RichText } from 'components/shared';
import springConfig from 'lib/utils/springConfigs';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Close } from './icons/ui';

export const articleRichTextOptions = richText => {
  const assets = richText.links?.assets?.block.reduce((acc, asset) => {
    acc[asset?.sys.id] = asset;
    return acc;
  }, {});

  const renderTable = (node, children) => {
    if (node.nodeType === BLOCKS.TABLE) {
      const rows = node.content.filter(child => child.nodeType === BLOCKS.TABLE_ROW);
      return (
        <div className="flex overflow-x-auto">
          <table className="w-full">
            <tbody>
              {rows.map((row, index) => (
                <tr
                  className="border-b border-solid border-b-[rgba(0,0,0,0.1)] text-blue"
                  key={index}
                >
                  {row.content.map((cell, cellIndex) => (
                    <td
                      className={`${
                        cell?.nodeType === BLOCKS.TABLE_HEADER_CELL
                          ? 'text-heading-7 font-bold'
                          : 'text-body-2'
                      } px-2 py-6`}
                      key={cellIndex}
                    >
                      {documentToReactComponents(cell, richTextOptions)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  const richTextOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const {
          data: { uri: href = '' },
        } = node;

        return (
          <a
            target="_blank"
            rel="noreferrer"
            href={href}
            className="font-nimbus-sans-d font-bold underline"
          >
            {children}
          </a>
        );
      },
      [BLOCKS.TABLE]: renderTable,
      [BLOCKS.EMBEDDED_ASSET]: ({ data }) =>
        assets ? (
          <img className="mb-[32px] mt-[23px]" src={assets[data?.target.sys.id].url} alt="denham" />
        ) : null,
      [BLOCKS.HEADING_1]: (_, children) => <h1 className="text-heading-1">{children}</h1>,
      [BLOCKS.HEADING_2]: (_, children) => <h2 className="text-heading-2">{children}</h2>,
      [BLOCKS.HEADING_3]: (_, children) => <p className="text-heading-3">{children}</p>,
      [BLOCKS.HEADING_4]: (_, children) => <p className="text-heading-4">{children}</p>,
      [BLOCKS.HEADING_5]: (_, children) => <p className="text-heading-5">{children}</p>,
      [BLOCKS.HEADING_6]: (_, children) => <p className="text-heading-6">{children}</p>,
      [BLOCKS.PARAGRAPH]: (_, children = []) => {
        const isEmpty = children.length === 1 && children[0] === '';
        if (isEmpty) {
          return <br />;
        }
        return <p>{addNewLines(children)}</p>;
      },
      [BLOCKS.UL_LIST]: (_, children) => (
        <ul className="mb-[25px] list-disc pl-[25px]">{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (_, children = []) => {
        const addBottomMargin = c =>
          c.type === 'p'
            ? { ...c, props: { ...c.props, className: `${c.props.className || ''} mb-[4px]` } }
            : c;
        const content = children.map(addBottomMargin);

        return <li className="pl-[7px]">{content}</li>;
      },
    },
  };
  return richTextOptions;
};

const ArticleAccordion = ({
  className,
  article,
  text,
  titleClass,
  submenuTitleClass,
  borders,
  onClick,
  open = false,
}) => {
  const contentRef = useRef();

  const [activeSubmenu, setActiveSubmenu] = useState();

  const hasSubmenus = article?.submenusCollection;
  const contentHeight =
    hasSubmenus || !contentRef.current ? 'auto' : contentRef.current.getBoundingClientRect().height;
  const isOpen = open || hasSubmenus;

  const accordionWrapperSpring = useSpring({
    height: isOpen ? contentHeight : 0,
    config: springConfig.accordionWrapper,
  });

  const accordionContentSpring = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translateY(0%)' : 'translateY(-15%)',
    config: springConfig.accordionContent,
  });

  const content =
    text ||
    (article?.article && (
      <div className={hasSubmenus ? 'mb-10' : ''}>
        <RichText
          data={{ json: article?.article?.json, links: article?.article?.links }}
          options={articleRichTextOptions(article?.article)}
        />
      </div>
    ));

  return (
    <div className={`${className} ${borders && !hasSubmenus ? 'border-b py-[14px]' : ''}`}>
      <button
        className={`flex w-full items-center justify-between ${
          !onClick ? 'pointer-events-none' : ''
        }`}
        type="button"
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        <p className={`${titleClass}`}>{article?.title}</p>
        {borders && !hasSubmenus && (
          <Close
            className={`w-[6px] ${
              open ? 'rotate-180 transform' : '-rotate-45 transform'
            } transition duration-100 ease-in-out`}
          />
        )}
      </button>
      <animated.div
        className={`whitespace-pre-line font-nimbus-sans-d text-[15px] leading-[1.4] will-change-[opacity,_transform] ${
          !hasSubmenus ? 'overflow-hidden' : ''
        }`}
        style={{ ...accordionWrapperSpring }}
      >
        <animated.div
          className="will-change-[opacity,_transform]"
          style={{ ...accordionContentSpring }}
        >
          <div ref={contentRef} className="py-[14px] lg:py-8">
            {content}
            {hasSubmenus &&
              article?.submenusCollection.items.map((submenu, i) => (
                <ArticleAccordion
                  open={activeSubmenu === i}
                  onClick={() => setActiveSubmenu(activeSubmenu === i ? null : i)}
                  article={submenu}
                  key={submenu?.title}
                  className={`mx-auto ${i === 0 ? 'mt-[14px] border-t' : ''}`}
                  titleClass={submenuTitleClass}
                  borders={borders}
                />
              ))}
          </div>
        </animated.div>
      </animated.div>
    </div>
  );
};

export default ArticleAccordion;
